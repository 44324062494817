function Base() {
  this.handleCarousels = function() {
    var bxCarousel = function(options) {
      return $.extend({
      }, options);
    };

    $(".js-page-home-carousel").bxSlider({
      mode: "fade",
      controls: false,
      auto: true
    });

    $(".js-page-static-carousel").bxSlider({
      mode: "fade",
      controls: false,
      auto: true
    });

    $(".js-profile-tab-carousel").bxSlider({
      auto: true,
      nextText: '<em class="fa  fa-chevron-right"></em>',
      prevText: '<em class="fa  fa-chevron-left"></em>'
    });

    $(".js-page-home-video-carousel").bxSlider({
      pager: false,
      minSlides: 7,
      maxSlides: 7,
      moveSlides: 1,
      slideWidth: 120,
      slideMargin: 22,
      nextText: '<em class="icon  icon-arrow-right-alternative"></em>',
      prevText: '<em class="icon  icon-arrow-left-alternative"></em>',
      auto: true
    });

    $(".js-site-footer-news").bxSlider({
      pager: false,
      minSlides: 3,
      maxSlides: 3,
      moveSlides: 1,
      slideWidth: 145,
      slideMargin: 15,
      nextText: '<em class="icon  icon-arrow-right-alternative"></em>',
      prevText: '<em class="icon  icon-arrow-left-alternative"></em>'
    });

    $(".js-my-goals-carousel").bxSlider({
      pager: false,
      minSlides: 4,
      maxSlides: 4,
      moveSlides: 1,
      slideWidth: 130,
      slideMargin: 20,
      nextText: '<em class="icon  icon-arrow-right"></em>',
      prevText: '<em class="icon  icon-arrow-left"></em>'
    });

    $(".js-sidebar-carousel").bxSlider({
      pager: false,
      nextText: '<em class="icon  icon-arrow-right"></em>',
      prevText: '<em class="icon  icon-arrow-left"></em>',
      onSliderLoad: function() {
        $(".sidebar-carousel .bx-wrapper").css("visibility", "visible");
      }
    });
  };

  this.handleDatepicker = function() {
    var currentYear = new Date().getFullYear();
    $(".js-datepicker").datepicker();
    $('.js-datepicker-year').datepicker({changeYear: true, yearRange: (currentYear-70) + ':' + (currentYear-5)});
  };

  this.handleRating = function() {
      $(function(){
        $(".js-rating").rating(function(vote, event){
          var container = $(event.currentTarget).parents('.js-rating'),
              action = container.data('href'),
              token = $('meta[name=token]').attr('content');

          $.ajax({
            url: action,
            type: 'post',
            dataType: 'json',
            data: {rating: vote, _token: token},
            success: function(data){
                if('success' == data.status){
                    swal({type: 'success', title: data.message,   text: "",   timer: 2000,   showConfirmButton: false});
                    var html = '<p class="text">Puan</p>' +
                               '<div class="stars-list">';
                    for(var i=1;i<=5;i++){
                        html += '<a class="star ' + (vote >= i ? 'tmp_fs' : '') + '" title="' + i + '"></a>';
                    }

                    html += '</div>';

                    container.parents('.rating').html(html);

                }else
                    swal({type: 'error', title: data.message.join('<br>'),   text: "Hata",   timer: 2000,   showConfirmButton: false});
            }
        });
      });
    });
  };

  this.tab = function() {
    $(".tab-title ul li").on("click", function(e) {
      var $this = $(this);

      if ($this.hasClass("current") || $this.hasClass("no-tab")) {
        return;
      }

      var overlay = $this.closest(".tab");
      var index = $this.index();

      overlay.children(".tab-title").find("li.current").removeClass("current");
      overlay.children(".tab-content").find("div.current").removeClass("current");

      overlay.children(".tab-title").find("li").eq(index).addClass("current");
      overlay.children(".tab-content").children("div").eq(index).addClass("current");
    });


    $(".action__buttons li").on("click", function(e){
      var $this = $(this);

      if ($this.hasClass("current") || $this.hasClass("no-tab")) {
        return;
      }

      var src = $this.parents('.action__buttons').data('src');

      if(!src)
          return;

      var overlay = $(".tab." + src);
      var index = $this.index();

      overlay.children(".tab-title").find("li.current").removeClass("current");
      overlay.children(".tab-content").find("div.current").removeClass("current");

      overlay.children(".tab-title").find("li").eq(index).addClass("current");
      overlay.children(".tab-content").children("div").eq(index).addClass("current");
    });
  };

  window.openLoginBox = function() {
    if('' === $('.js-login-box .box-inner').html()){
        $.ajax({
            url: '/giris',
            success: function(resp){
                $('.js-login-box .box-inner').html(resp);
                $(".js-login-box").fadeIn();

                $(".box-overlay, a.close").on("click", function() {
                  $(".js-login-box").fadeOut();
                });
            }
        });
    }else{
        $(".js-login-box").fadeIn();
        $(".box-overlay, a.close").on("click", function() {
          $(".js-login-box").fadeOut();
        });
    }
  };

  this.openVideoBox = function() {
    $(".js-open-video-box").on("click", function() {
      $(".js-video-box").show();
      document.getElementById("shsmovie").play();
    });

    $(".box-overlay, a.close").on("click", function() {
      document.getElementById("shsmovie").pause();
      $(".js-video-box").fadeOut();
    });
  };

  this.hoverComments = function() {
    $(".comments-area li.item .text").on("mouseover", function(e) {
      e.stopPropagation();
      $(this).parent().addClass("hover");
    });

    $(".comments-area li.item .text").on("mouseout", function(e) {
      e.stopPropagation();
      $(this).parent().removeClass("hover");
    });
  };

  this.openBox = function(link) {
    window.open(
      link,
      'share-dialog',
      'width=626, height=436'
    );
  };

  this.socialBox = function() {
    var self = this;

    $(".js-box").on("click", function(e) {
      e.preventDefault();
      e.stopPropagation();
      var link = $(this).data("link");

      switch ($(this).data("social")) {
        case "facebook":
          self.openBox("https://www.facebook.com/sharer/sharer.php?u=" + link);
          break;

        case "twitter":
          var text = $(this).data("text");
          self.openBox("http://twitter.com/home?status=" + text + " " + link + " @SosyalHaliSaha aracılığıyla");
          break;

        case "google":
          self.openBox("https://plus.google.com/share?url=" + link);
          break;
      }
    });
  };

  this.headerSearch = function() {
    $(".js-search-toggle").on("click", function() {
      $(".js-search-area").stop().slideToggle();
    });
  };

  this.triggerMobile = function() {
    $('.menu__toggle a').on('click', function(){
      $('.menu__shadow').show();
      $('body').addClass('menu__active');
    });

    $('.menu__shadow').on('click', function(){
      $('.menu__shadow').hide();
      $('body').removeClass('menu__active');
    });

    $('.mobile__navigation li a').on('click', function(){
      if($(this).parent().find('> ul').length > 0) {
        $(this).parent().find('> a').toggleClass('active');
        $(this).parent().find('> ul').stop().slideToggle();
      }
    });

    $('.popup__toggle').on('click', function(){
      var targetItem = $(this).data('target');
      $('.mobile__modal').each(function(){
        if ($(this).data('id') == targetItem) {
          $(this).stop().fadeIn();
          $('.modal__backdrop').stop().fadeIn();
        }
      });
    });

    $('.modal__backdrop, .mobile__modal .close-button').on('click', function(){
      $('.mobile__modal').stop().fadeOut();
      $('.modal__backdrop').stop().fadeOut();
    });
  };

  this.init = function() {
    $("[href='#']").on("click", function(e) {
      e.preventDefault();
    });

    if(navigator.userAgent.toLowerCase().indexOf("android") < 0) {
        $(":input").inputmask();
    }

    $(".page-static__accordion").shsaccordion(false);

    this.handleCarousels();
    this.handleDatepicker();
    this.handleRating();
    this.tab();
    this.hoverComments();
    this.socialBox();
    this.headerSearch();
    this.openVideoBox();
    this.triggerMobile();
  };
}
