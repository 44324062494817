(function($){
    $(document).ajaxError(function(event, xhr){
        if(401 == xhr.status){
            openLoginBox();
        }else if(xhr.responseJSON.status && 'error' == xhr.responseJSON.status)
            swal({type: 'error', title: xhr.responseJSON.message,   text: "Hata",   timer: 2000,   showConfirmButton: false});
    });

    function refreshToken(){
        $.ajax({
            url: $('meta[name=base_url]').attr('content') + '/refresh',
            dataType: 'json',
            success: function(data){
                var metaToken = $('meta[name=token]'),
                    inputToken = $('input[type=hidden][name=_token]');

                if('success' == data.status && '' !== data.token){
                    metaToken.attr('content', data.token);
                    if(inputToken.length > 0){
                        inputToken.map(function(){
                            this.value = data.token;
                        });
                    }
                }
            }
        });

        setTimeout(refreshToken, 1000*60*5);
    }

    refreshToken();

    var searchForm = $('.site-header__search, .site__search'),
        i = 0;
    if(searchForm.length > 0){
        var districtComplete = function(){
            var filterDistrict = $(this),
                filterPlace = filterDistrict.parents('form').find('.filter_place'),
                token = $('meta[name=token]').attr('content');

            filterPlace.find('option.dataValue').remove();
            if('' !== filterDistrict.val()){
                $.ajax({
                    url: '/filtre',
                    dataType: 'json',
                    type: 'post',
                    data: '_token=' + token + '&district=' + filterDistrict.val() + '&type=getplace',
                    success: function(data){
                        if('success' == data.status){
                            for(var i=0;i<data.data.length;i++)
                                filterPlace.append('<option value="' + data.data[i].id + '" class="dataValue">' + data.data[i].title + '</option>');
                            if(filterPlace.data('value'))
                                filterPlace.val(filterPlace.data('value'));
                        }
                    }
                });
            }
        };

        searchForm.each(function(){
            $(this).find('.filter_district').change(districtComplete).trigger('change');
        });
    }

    var registerForm = $('form[name=register], form[name=profile]');
    if(registerForm.length > 0){
        var userinfoCity = registerForm.find('select[name="userinfo[city_id]"]');
        userinfoCity.change(function(){
            var districts = registerForm.find('select[name="userinfo[district_id]"]'),
                $this = $(this),
                token = $('meta[name=token]').attr('content');
            
            districts.find('option.dataValue').remove();
            if('' !== $this.val()){
                $.ajax({
                    url: '/ilce',
                    dataType: 'json',
                    type: 'post',
                    data: '_token=' + token + '&city_id=' + $this.val(),
                    success: function(data){
                        if('success' == data.status)
                            for(var i=0;i<data.data.length;i++)
                                districts.append('<option value="' + data.data[i].id + '" class="dataValue" ' + (districts.data('value') == data.data[i].id ? 'selected': '') + '>' + data.data[i].name + '</option>');
                    }
                });
            }
        });
        userinfoCity.trigger('change');
    }

    var commentTab = $('.tab.comments .tab-content #comments-area');
    if(commentTab.length>0 && commentTab.data('id')){
        getComments(commentTab);

        var commentForm = $('.new-comment form');
        if(commentForm.length>0){
            var handleCommentForm = function(commentForm){
                commentForm.submit(function(){
                    $.ajax({
                        url: commentForm.attr('action'),
                        type: 'post',
                        dataType: 'json',
                        data: commentForm.serialize(),
                        success: function(data){
                            if('success' == data.status){
                                getComments($('.tab.comments .tab-content #comments-area'));
                                commentForm.find('input[name=parent], textarea').val('');
                                $('.comment-count').text(data.data.count);
                                swal({type: 'success', title: 'Yorumunuz kaydedildi',   text: "",   timer: 2000,   showConfirmButton: false});
                            }else
                                swal({type: 'error', title: data.message,   text: "Başarısız",   timer: 2000,   showConfirmButton: false});
                        }
                    });
                    return false;
                });

                $('textarea[name=comment]').unbind('keyup').keyup(function(e){
                    if(e.keyCode == 13)
                        commentForm.submit();
                });
            };

            for(var i = 0; i < commentForm.length; i++){
                handleCommentForm($(commentForm[i]));
            }
        }
    }

    var formVideoCut = $('#form-video-cut'),
        formVideoCutMobile = $('#form-video-cut-mobile'),
        handleVideoCut = function(formVideoCut){
            formVideoCut.find('.cut-submit').click(function(e){
                e.stopPropagation();
                e.preventDefault();

                var start = formVideoCut.find('input[name=start]').val(),
                    end = formVideoCut.find('input[name=end]').val(),
                    description = formVideoCut.find('input[name=description]').val(),
                    type = formVideoCut.find('select[name=type]').val(),
                    token = $('meta[name=token]').attr('content'),
                    timeDiff = function(start, end){
                        start = start.split(':');
                        end = end.split(':');
                        if(start.length!=2 || end.length!=2 || isNaN(start[0]) || isNaN(start[1]) || isNaN(end[0]) || isNaN(end[1]))
                            return false;
                        return (parseInt(end[0])*60 + parseInt(end[1])) - (parseInt(start[0])*60 + parseInt(start[1]));
                    },
                    diff = timeDiff(start, end),
                    messageBox = formVideoCut.find('.cut-goal .message'),
                    error,
                    types = ['goal', 'position', 'saves'],
                    typeAvailable = false,
                    i;

                if(messageBox.length>0)
                    messageBox.remove();

                for(i = 0; i < types.length; i++){
                    if(type == types[i])
                        typeAvailable = true;
                }
                
                if(false === typeAvailable)
                    error = 'Geçerli bir pozisyon tipi seçiniz';
                
                if(false === diff || diff < 0)
                   error = 'Girdiğiniz zaman bilgisini kontrol edin.';

                if(diff > 30)
                    error = 'Gol videosu 30 saniyeden uzun olamaz.';

                if(error)
                    swal({type: 'error', title: error,   text: "Hata",   timer: 2000,   showConfirmButton: false});
                else{
                    $.ajax({
                        url: formVideoCut.attr('action'),
                        dataType: 'json',
                        type: 'post',
                        data: 'id=' + formVideoCut.data('id') + '&start=' + start + '&end=' + end + '&description=' + description + '&type=' + type + '&_token=' + token,
                        success: function(data){

                            switch(data.status){
                                case 'error':
                                    swal({html: true, type: 'error', title: data.message, text: "Hata",   timer: 2000,   showConfirmButton: false});
                                    break;
                                case 'success':
                                    swal({html: true, type: 'success', title: data.message, text: "Başarılı",   timer: 5000,   showConfirmButton: false});
                                    break;
                            }

                            formVideoCut.find('.cut-goal:eq(0)').prepend('<div class="noticifation-message notification-message--' + data.status + '"><p>' + data.message + '</p></div>');
                            formVideoCut[0].reset();
                        }
                    });
                }
                
            });
        };

    if(formVideoCut.length>0)
        handleVideoCut(formVideoCut);

    if(formVideoCutMobile.length>0)
        handleVideoCut(formVideoCutMobile);
    

    $('video').bind('contextmenu', function(){
        return false;
    });

    $('.points a').click(function(e){
        e.stopPropagation();
        e.preventDefault();

        $(this).siblings().removeClass('active');
        $(this).addClass('active');
    });

    $('#btn-point-submit').click(function(e){
        e.stopPropagation();
        e.preventDefault();

        var rating = $('.points a.active').data('value'),
            action = $('.points').data('action'),
            _token = $('meta[name=token]').attr('content'),
            section = $('#rating-section'),
            button = $(this);

        if(button.hasClass('processing'))
            return false;

        button.addClass('processing');

        $.ajax({
            url: action,
            type: 'post',
            dataType: 'json',
            data: '_token=' + _token + '&rating=' + rating,
            success: function(data){
                var message = $('<p class="message"></p>');
                section.prepend(message);
                message.text(typeof data.message == 'string' ? data.message : data.message[0]);
                setTimeout(function(){
                    message.fadeOut('fast', function(){
                        $(this).remove();
                        button.removeClass('processing');
                    });
                }, 3000);
            }
        });

    });

    $('.played-this').click(function(e){
        e.stopPropagation();
        e.preventDefault();

        var $this = $(this),
            token = $('meta[name=token]').attr('content');

        $.ajax({
            url: $this.data('href'),
            type: 'post',
            dataType: 'json',
            data: '_token=' + token,
            success: function(data){
                if('success' == data.status){
                    //$this.siblings('p').text('Bu maçta oynadınız');
                    var text = '';
                    switch(data.played){
                        case 0:
                            text = 'Bu maçta oynamadınız';

                            $this.find('em').removeClass('fa-minus-square').addClass('fa-plus-square');                            
                            break;
                        case 1:
                            text = 'Bu maçta oynadınız';
                            $this.find('em').removeClass('fa-plus-square').addClass('fa-minus-square');
                            break;
                        default:
                            break;
                    }
		    swal({type: 'success', title: text,   text: "",   timer: 2000,   showConfirmButton: false});
                    $this.find('span').text(data.text);

                }else{
                    swal({type: 'error', title: data.message,   text: "Başarısız",   timer: 2000,   showConfirmButton: false});
                }
            }
        });
    });

    $('.follow').click(function(e){
        e.preventDefault();
        e.stopPropagation();
        var $this = $(this),
            token = $('meta[name=token]').attr('content');

        $.ajax({
            url: $(this).attr('href'),
            dataType: 'json',
            type: 'post',
            data: '_token=' + token,
            success: function(data){
                if(data && 'success' == data.status)
                    $this.text(data.text);

                swal({type: data.status, title: data.message, text: '', timer: 2000, showConfirmButton: false});
                setTimeout(function(){
                    window.location.reload();
                }, 2000);
            }
        });
    });

    $('.submit_report').click(function(e){
        e.stopPropagation();
        e.preventDefault();
    
        var form = $(this).parents('form'),
            token = $('meta[name=token]').attr('content');

        makeRequest(form, token);

    });

    $('.add-to-favorite').click(function(e){
        e.stopPropagation();
        e.preventDefault();

        var form = $(this).parents('form'),
            token = $('meta[name=token]').attr('content'),
            elem = $(this);

        makeRequest(form, token, function(){
            var add = form.find('input[name=add]');
            if(add.val() == '0'){
                add.val(1);
                elem.find('span').text('Favorilere ekle');
            }else{
                add.val(0);
                elem.find('span').text('Favorilerden çıkar');
            }
        });
    });

    $('#place-message-form.ajax').submit(function(e){
        var data = $(this).serialize(),
            action = $(this).attr('action'),
            $this = $(this);

        $.ajax({
            url: action,
            type: 'post',
            dataType: 'json',
            data: data,
            success: function(data){
                $('p.message').remove();
                if('success' == data.status)
                    $this[0].reset();

                if('string' == typeof data.message)
                    showMessage(data.message, $this, 2000);
                else{
                    for(var i=0;i<data.message.length;i++)
                        showMessage(data.message[i], $this, 2000);
                }
            }
        });

        return false;
    });

    var timeCounter = $('.time_counter');
    if(timeCounter.length>0){
        timeCounter.each(function(i, elem){
            (function(elem){
                countTime($(elem));
            })(elem);
        });
    }

    $('.redirect_location').change(function(){
        window.location.href = $(this).val();
    });

    $('.fb-share').click(function(e){
        e.preventDefault();
        fbShare();
    });

    $('.twitter-share').click(function(e){
        e.preventDefault();
        twitterShare();
    });


    window.fbAsyncInit = function(){
        FB.init({appId : '1743525592531997', xfbml : true, version : 'v2.5', status: true, cookie: true});
        FB.getLoginStatus(function(response) {
            fbStatus(response);
        });
    };

    (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) 
            return;
        js = d.createElement(s); 
        js.id = id;
        js.src = "//connect.facebook.net/tr_TR/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    $('.fbLogin').click(function(e){
        e.preventDefault();
        e.stopPropagation();
        FB.login(function(status){});
    });

    $('.autocomplete.manager-name').keyup(function(e){
        var keyCodes = [8, 13, 37, 39, 46];
        for(var i=0;i<keyCodes.length;i++){
            if(keyCodes[i] == e.keyCode)
                return;
        }

        var $this = $(this),
            token = $('meta[name=token]').attr('content'),
            query = $this.val();

        if(query.length > 2){
            $.ajax({
                url: $this.data('action'),
                data: 'q=' + query + '&_token=' + token,
                dataType: 'json',
                type: 'post',
                success: function(data){
                    if(data && data.length>0 && undefined !== data[0].username){
                        $this.val(data[0].username);
                        selectText($this[0], query.length, data[0].username.length);
                    }
                }
            });
        }
    });

    $('.autocomplete.message-toname').keyup(function(e){
        var keyCodes = [8, 13, 37, 39, 46];
        for(var i=0;i<keyCodes.length;i++){
            if(keyCodes[i] == e.keyCode)
                return;
        }


        var $this = $(this),
            token = $('meta[name=token]').attr('content'),
            query = $this.val(),
            container = $('.autocomplete-container');

        if(container.length>0)
            container.remove();

        if(query.length > 2){
            $.ajax({
                url: $this.data('action'),
                data: 'q=' + query + '&_token=' + token,
                dataType: 'json',
                type: 'post',
                success: function(data){
                    if(data && data.length>0){
                        var container = $('<div class="autocomplete-container"><ul></ul></div>'),
                            ul = container.find('ul'),
                            li,
                            handleClick = function(e){
                                $this.val($(this).text());
                                container.remove();
                            };

                        for(var i=0;i<data.length;i++){
                            li = $('<li>' + data[i].name + '</li>');
                            li.click(handleClick);
                            ul.append(li);
                        }

                        $this.after(container);
                    }
                }
            });
        }
    });

    $('#manager-submit').click(function(e){
        e.preventDefault();
        e.stopPropagation();
        
        var uname = $('input.manager-name').val(),
            $this = $(this),
            token = $('meta[name=token]').attr('content');

        if(uname.length>2){
            $.ajax({
                url: $this.data('action'),
                type: 'post',
                dataType: 'json',
                data: '_token=' + token + '&username=' + uname,
                success: function(data){
                    swal({type: data.status, title: data.message,  text: "",   timer: 2000,   showConfirmButton: false});
                    if('success' == data.status){
                        setTimeout(function(){
                            window.location.reload();
                        }, 2000);
                    }
                }
            });
        }
    });

    $('.image-delete').click(function(e){
        e.preventDefault();
        e.stopPropagation();
        var $this = $(this);
        swal({type: 'warning', title: 'Resmin silinmesini onaylıyor musunuz?', showConfirmButton: true, showCancelButton: true, closeOnConfirm: false, closeOnCancel: true}, function(confirmed){
            if(confirmed){
                window.location.href = $this.attr('href');
            }
        });
    });

    $('.filter-form').submit(function(){
        var inputs = $(this).serializeArray(),
            objInputs = {},
            arrInputs = [];

        for(var i=0;i<inputs.length;i++)
            objInputs[inputs[i].name] = inputs[i].value;

        arrInputs.push(objInputs.district ? objInputs.district : '');
        arrInputs.push(objInputs.place ? objInputs.place : '');
        arrInputs.push(objInputs.date ? objInputs.date : '');
        arrInputs.push(objInputs.time ? objInputs.time : '');
        arrInputs.push(objInputs.user ? objInputs.user : '');
        window.location.href = $(this).attr('action') + '/' + (arrInputs.join('_').replace(/\s/g, '+'));
        return false;
    });

    var drag = $('.drag');
    if (drag.length>0) {

        drag.on("dragover", function(e) {
            e.preventDefault();
            e.stopPropagation();
            $(this).addClass('dragging');
        });

        drag.on("dragleave", function(e) {
            e.preventDefault();
            e.stopPropagation();
            $(this).removeClass('dragging');
        });


        drag[0].addEventListener("drop", handleFileSelect, true);
    }

    $("#image_upload").on("change", handleFileSelect);

    $('.show-login').click(function(e){
        e.preventDefault();
        e.stopPropagation();

        openLoginBox();
    });

    $('#gotw-week-select').change(function(e){
        window.location.href = $(this).val();
    });


    var activateAudio = false,
        fpPlayer = $('.fp-player'),
        url = $('meta[name=base_url]').attr('content');

    if(fpPlayer.length > 0){
        audio = $(fpPlayer.data('audio')),

        flowplayer(fpPlayer, {
          ima: {
            description_url: location.href,
          },
          swf: url + '/flowplayer/flowplayer.swf',
          swfHls: url + '/flowplayer/flowplayerhls.swf',
          splash: false,
          embed: false,
          key: "$165704730856542",
          autoplay: true,
          playlist: [
            {
              ima: {
                ads: [{
                  time: 0,
                  ad_type: "standardvideo",
                  max_ad_duration: 60000
                }]
              },
              sources: [
                {type: "video/mp4",
                 src: fpPlayer.data('video')},
              ]
            }/*,
            {
              sources: [
                {type: "video/mp4",
                 src: fpPlayer.data('intro')},
              ]
            }*/
          ],
          speeds: [0.25, 0.5, 1, 1.5, 2]
        }).on('ready', function(e, o, v){
            if(v.src == fpPlayer.data('video')){
                activateAudio = true;
                audio[0].play();
            }else{
                activateAudio = false;
                audio[0].pause();
                audio[0].currentTime = 0;
            }
        }).on('resume', function(){
            if(activateAudio)
                audio[0].play();
        }).on('pause', function(){
            if(activateAudio)
                audio[0].pause();
        }).on('stop', function(){
            if(activateAudio){
                audio[0].pause();
                audio[0].currentTime = 0;
            }
        }).on('finish', function(){
            if(activateAudio){
                audio[0].pause();
                audio[0].currentTime = 0;
            }
        }).on('volume', function(e, o, v){
            audio[0].volume = v;
        });
    }
})(jQuery);


function handleFileSelect(e){
    e.preventDefault();
    e.stopPropagation();

    /*
    var fileList = e.target.files,
        fileReader;

    if (!fileList || fileList.length === 0)
        fileList = e.dataTransfer.files;
    */

    $('#place-image-form').submit();
}

function selectText(input, start, end){
    input.focus();
    if (typeof input.selectionStart != "undefined") {
        input.selectionStart = start;
        input.selectionEnd = end;
    } else if (document.selection && document.selection.createRange) {
        input.select();
        var range = document.selection.createRange();
        range.collapse(true);
        range.moveEnd("character", end);
        range.moveStart("character", start);
        range.select();
    }
}

function fbStatus(response){
    if(response.status === 'connected')
      fbUserInfo();
}

function fbUserInfo(){
    FB.api('/me', function(response){});
}

function fbLogin(){
    FB.getLoginStatus(function(response){});
}

function makeRequest(form, _token, callback){
    $.ajax({
        url: form.attr('action'),
        data: form.serialize() + '&_token=' + _token,
        dataType: 'json',
        type: 'post',
        success: function(data){
            if('success' == data.status){
                swal({type: 'success', title: data.message,   text: "",   timer: 2000,   showConfirmButton: false});
                if('function' == typeof callback)
                    callback();
            }else if('error' == data.status){
                if(typeof data.message == 'string')
                    swal({type: 'error', title: data.message,   text: "Başarısız",   timer: 2000,   showConfirmButton: false});
                else{
                    swal({type: 'error', title: data.message.join('<br>'),   text: "Başarısız",   timer: 2000,   showConfirmButton: false});
                }
            }
        }
    });
}

function countTime(elem){
    setTimeout(function(){
        timeToStr(elem.data('time'), function(strTime){
            elem.data('time', parseInt(elem.data('time'))-1);
            elem.text(strTime);
        });

        countTime(elem);
    }, 1000);
}

function timeToStr(time, callback){
    var objTime = {s:0, m:0, h:0, d:0},
        arrTime = [];

    objTime.s = time;
    if(time >= 60){
        objTime.s = time%60;
        time = Math.floor(time/60);

        objTime.m = time;
        if(time >= 60){
            objTime.m = time%60;
            time = Math.floor(time/60);

            objTime.h = time;
            if(time >= 24){
                objTime.h = time%24;
                time = Math.floor(time/24);

                objTime.d = time;
            }
        }
    }

    //strTime = objTime.d + ' Gün ' + [1 == ('' + objTime.h).length ? '0' + objTime.h : objTime.h, 1 == ('' + objTime.m).length ? '0' + objTime.m : objTime.m, 1 == ('' + objTime.s).length ? '0' + objTime.s : objTime.s].join(':');
    //strTime = objTime.d + ' Gün ' + objTime.h + ' saat ' + objTime.m + ' dakika ' + objTime.s + ' saniye';

    if(objTime.d > 0)
        arrTime.push(objTime.d + ' gün');
    if(objTime.h > 0)
        arrTime.push(objTime.h + ' saat');
    if(objTime.m > 0)
        arrTime.push(objTime.m + ' dakika');
    if(objTime.s > 0)
        arrTime.push(objTime.s + ' saniye');
    strTime = arrTime.join(' ');

    if('function' == typeof callback)
        callback(strTime);
    else
        return strTime;
    
}

function showMessage(message, cont, timeout){
    var elem = $('<p class="message">' + message + '</p>');
    cont.prepend(elem);
    setTimeout(function(){elem.remove();}, timeout);
}

function getComments(commentTab, page){
    page = page ? page : 1;

    $.ajax({
        url: commentTab.data('url'),
        type: 'post',
        data: 'type=' + commentTab.data('type') + '&id=' + commentTab.data('id') + '&page=' + page,
        success: function(data){
            commentTab.html(data);
            triggerComments();
        }
    });
}

function triggerComments(){
    var commentForm = $('.new-comment form');

    $('#comments-area .reply').unbind('click').click(function(e){
        e.stopPropagation();
        e.preventDefault();
        
        commentForm.find('input[name=parent]').val($(this).parent().data('id'));
        $('textarea[name=comment]').focus();

    });

    $('#comments-area .pagination a').unbind('click').click(function(e){
        e.stopPropagation();
        e.preventDefault();

        getComments($('.tab.comments .tab-content #comments-area'), $(this).data('page'));
    });

    $('#comments-area .report').unbind('click').click(function(e){
        e.stopPropagation();
        e.preventDefault();

        var $this = $(this),
            token = $('meta[name=token]').attr('content');
        
        $.ajax({
            url: $this.attr('href'),
            type: 'post',
            dataType: 'json',
            data: '_token=' + token + '&type=inappropriate',
            success: function(data){
                if('success' == data.status)
                    swal({type: 'success', title: data.message,   text: "",   timer: 2000,   showConfirmButton: false});
                else
                    swal({type: 'error', title: data.message,   text: "",   timer: 2000,   showConfirmButton: false});
            }
        });
    });

    (new Base()).hoverComments();
}

function fbShare(){
    FB.ui({
          method: 'share',
          href: window.location.href,
    }, function(response){});
}

function twitterShare(){
    var width  = 575,
        height = 400,
        left   = ($(window).width()  - width)  / 2,
        top    = ($(window).height() - height) / 2,
        url    = 'http://twitter.com/share',
        opts   = 'status=1' +
                 ',width='  + width  +
                 ',height=' + height +
                 ',top='    + top    +
                 ',left='   + left;
    
    window.open(url, 'twitter', opts);
    return false;
}
