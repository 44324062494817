(function($) {
  'use strict';

  $.fn.extend({
    shsaccordion: function(icon) {
      return this.each(function() {
        var name = $(this).attr('class');

        $('.' + name + '-title').on('click', function() {
          var accordionOverlay = $(this).closest('.' + name),
            accordionItem = $(this).parent(),
            accordionContent = accordionItem.children('.' + name + '-content'),
            activeText = name + '-item--current';

          if (accordionItem.hasClass(activeText)) {
            return;
          }

          accordionOverlay
            .find('.' + name + '-content')
            .slideUp();

          accordionContent
            .slideDown({
              duration: 500,
              complete: function() {
                accordionOverlay
                  .find('.' + name + '-item')
                  .removeClass(activeText);

                accordionItem
                  .addClass(activeText);
              }
            });
        });
      });
    }
  });
})(jQuery);
